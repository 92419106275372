import * as React from 'react';

// Components
import AnnouncementCard from '../../components/card/Announcement';

const AnnouncementsSection = ({ data, announcements }) => {
  return (
    <>
      <div className="row gx-3 mb-7">
        {announcements.nodes &&
          announcements.nodes.map(announcement => (
            <AnnouncementCard key={announcement.id} data={announcement} cardStyle="5" cardClassName="col-sm-6 col-lg-4 mb-4 mb-lg-0"/>
          ))
        }
      </div>
    </>
  );
};

export default AnnouncementsSection