import * as React from 'react';

// Components
import ResourceCard from '../../components/card/Resource';

const ResourcesSection = ({ data, resources }) => {
  return (
    <>
      <div className="row gx-3 mb-7">
        {resources.nodes &&
          resources.nodes.map(resource => (
            <ResourceCard key={resource.id} data={resource} cardStyle="1" cardClassName="col-sm-6 col-lg-4 mb-4 mb-lg-0"/>
          ))
        }
      </div>
    </>
  );
};

export default ResourcesSection