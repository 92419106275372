import * as React from 'react';

// Components
import ArticleCard from '../../components/card/Article';

const ArticlesSection = ({ data, articles }) => {
  return (
    <>
      <div className="row gx-3 mb-7">
        {articles.nodes &&
          articles.nodes.map(article => (
            <ArticleCard key={article.id} data={article} cardStyle="1" cardClassName="col-sm-6 col-lg-4 mb-4 mb-lg-0"/>
          ))
        }
      </div>
    </>
  );
};

export default ArticlesSection